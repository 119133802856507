/**
 * Global utilities
 * (c) 2012-2013 Ogeane Uk Ltd
 * Adapted for IFHOP
 * Work for JQM 1.3.x series
 * Move to Bootstrap in progress
 */
 //---------------------------------------------------------------------------------------------------
 function popuppdf (reference, callback)
 {
   // if (hpdfpopup == null)
   var  hpdfpopup = window.open(reference, 'wogpdf', "height=500, width=500, left=50, top=50, resizable=yes, scrollbars=yes, toolbar=yes, directories=no, location=no");
   //else
   //  hpdfpopup.location=reference;

   hpdfpopup.focus();
   if (typeof callback !== 'undefined')
     {
      window.setTimeout(callback, 1000);
     }
 }

var findEventHandlers = function (eventType, jqSelector) {
    var results = [];
    var $ = jQuery;// to avoid conflict between others frameworks like Mootools

    var arrayIntersection = function (array1, array2) {
        return $(array1).filter(function (index, element) {
            return $.inArray(element, $(array2)) !== -1;
        });
    };

    var haveCommonElements = function (array1, array2) {
        return arrayIntersection(array1, array2).length !== 0;
    };


    var addEventHandlerInfo = function (element, event, $elementsCovered) {
        var extendedEvent = event;
        if ($elementsCovered !== void 0 && $elementsCovered !== null) {
            $.extend(extendedEvent, { targets: $elementsCovered.toArray() });
        }
        var eventInfo;
        var eventsInfo = $.grep(results, function (evInfo, index) {
            return element === evInfo.element;
        });

        if (eventsInfo.length === 0) {
            eventInfo = {
                element: element,
                events: [extendedEvent]
            };
            results.push(eventInfo);
        } else {
            eventInfo = eventsInfo[0];
            eventInfo.events.push(extendedEvent);
        }
    };


    var $elementsToWatch = $(jqSelector);
    if (jqSelector === "*")//* does not include document and we might be interested in handlers registered there
        $elementsToWatch = $elementsToWatch.add(document);
    var $allElements = $("*").add(document);

    $.each($allElements, function (elementIndex, element) {
        var allElementEvents = $._data(element, "events");
        if (allElementEvents !== void 0 && allElementEvents[eventType] !== void 0) {
            var eventContainer = allElementEvents[eventType];
            $.each(eventContainer, function(eventIndex, event){
                var isDelegateEvent = event.selector !== void 0 && event.selector !== null;
                var $elementsCovered;
                if (isDelegateEvent) {
                    $elementsCovered = $(event.selector, element); //only look at children of the element, since those are the only ones the handler covers
                } else {
                    $elementsCovered = $(element); //just itself
                }
                if (haveCommonElements($elementsCovered, $elementsToWatch)) {
                    addEventHandlerInfo(element, event, $elementsCovered);
                }
            });
        }
    });

    return results;
};








String.prototype.toProperCase = function()
{
  return this.toLowerCase().replace(/^(.)|\s(.)/g,
      function($1) { return $1.toUpperCase(); });
}

if (!String.prototype.trim) {
  String.prototype.trim=function(){return this.replace(/^\s+|\s+$/g, '');};
  String.prototype.ltrim=function(){return this.replace(/^\s+/,'');};
  String.prototype.rtrim=function(){return this.replace(/\s+$/,'');};
  String.prototype.fulltrim=function(){return this.replace(/(?:(?:^|\n)\s+|\s+(?:$|\n))/g,'').replace(/\s+/g,' ');};

}
/**
*
*  Javascript string pad
*  http://www.webtoolkit.info/
*
**/

STR_PAD_LEFT = 1;
STR_PAD_RIGHT = 2;
STR_PAD_BOTH = 3;

function pad(str, len, pad, dir) {

  if (typeof(len) == "undefined") { var len = 0; }
  if (typeof(pad) == "undefined") { var pad = '0'; }
  if (typeof(dir) == "undefined") { var dir = STR_PAD_LEFT; }
  if (typeof(str) != 'string')
     str = str.toString();

  if (len + 1 >= str.length) {

    switch (dir){

      case STR_PAD_LEFT:
        str = Array(len + 1 - str.length).join(pad) + str;
      break;

      case STR_PAD_BOTH:
        var right = Math.ceil((padlen = len - str.length) / 2);
        var left = padlen - right;
        str = Array(left+1).join(pad) + str + Array(right+1).join(pad);
      break;
      default:
        str = str + Array(len + 1 - str.length).join(pad);
      break;
    } // switch
  }
  return str;
}

var g= (function () {

 // put private variables here

return {
    /**
    * Reset a form for jQuery mobile including possible localstorage save id
    *
    * e can be an object (event) the event target MUST have data-ogformname set  or a string (id of the form)
    *
    * note: on hidden fields
    */
   mbformreset: function (e)
      {
      var cnt;

      if (typeof e == 'string')
        cnt=$(e)
      else
        {
        e.preventDefault(); // prevent normal button action
        cnt=$(e.target).data('ogformname') || '';
        if (cnt === '')
          return;
        cnt=$('#'+cnt);
        }
      if (cnt.length===0)
        return;
      cnt.removeData('ogformsaveid');

      $('input, textarea', cnt).not('[data-type="range"]')
            .not('[type="radio"]')
            .not('[type=checkbox]')
            .not('[type=hidden]')
            .val(''); // clear inputs except buttons, setting value to blank
      // do not use ':hidden' as it brings everything hidden divs, input ...
      $('[type="hidden"]').filter(':not(.ognoreset)', cnt).val('');

      // clear select
      $('select',cnt).not(':jqmData(role=slider)').val('').prop('selectedIndex', -1).filter ('ui-select').selectmenu();

      // clear slider (ie yes/no type)
      // $('select',cnt).filter(':jqmData(role=slider)').val('0').filter (function()
      //     {alert('slider '+$(this).attr('id')); return $(this).slider ? true : false;}).slider('refresh');

      $('select',cnt).filter(':jqmData(role=slider)').val('0').filter ('.ui-slider-switch').slider('refresh');
      // range with horizontal line
      $('input',cnt).filter('[data-type="range"]').val(0).filter (function()
          {return $(this).slider ? true : false;}).slider('refresh');
      // checkbox and radio button - do not use val() as it clears the value field!!!!!
      $('[type=checkbox]',cnt).prop('checked',false).attr('checked',false).each (function()
          {
            try
              {$(this).checkboxradio("refresh");}
            catch (ex) {  }
          });
      $('[type=radio]',cnt).prop('checked',false).attr('checked',false).each(function()
          {
            try
              {$(this).checkboxradio("refresh");}
            catch (ex) {   }
          });
      },

   mbalert: function (msgtext, afterclose) {

    var m=$('#messagedialog');
    // var ln=$('#messagedialoglnk');

    if (m.length != 0)
      m.remove();

    m=$("<div />", {'id': 'messagedialog', 'data-role':'popup', 'data-history':'false' ,
          'class':'ui-content',
          'style':'max-width:300px; min-width:120px',
          'data-history':'false',
          'data-transition':'flow',
          })
      // $('<a />', {'href':'#', 'data-role':'button', 'data-rel':'back',
      //                 'data-theme':'b', 'data-icon':'delete',
      //                 'data-iconpos':'notext', 'class':'ui-btn-left'}).html('Close').appendTo(m);
      $('<p />', {'id': 'innermessagedialog'}).appendTo(m) ;


      // var l=$('<a />').attr('href','#').attr('id','buttondialog').jqmData('role','button').jqmData('rel', 'back')
      //    .jqmData('mini', true).jqmData('inline', true).css('padding', '10px').val('ok');
      // s.append( l );
      $.mobile.activePage.append(m);
      // $('#buttondialog').button();
      // m=$('#messagedialog');


    $('#innermessagedialog').html (msgtext);
    m.popup({ history: false });

    if (typeof afterclose == 'function')
       m.bind('popupafterclose', afterclose );
    m.bind('popupafterclose', function () {
      m.remove();
    } );
    m.popup('open');

   },

   getMessage: function (code) {
    var ms='';
      if  (screenLanguage === null )
        return '';
      else {
          if (screenLanguage == '')
             screenLanguage = 'en'
          ms = msg[screenLanguage][code.toUpperCase()];
          if (ms == undefined)
            console.log (screenLanguage + ':missing ' + code.toUpperCase() )
          else
            return ms
        }
     },
    translate: function ()
    {
      var that=this;
      $('[data-ogtrans]').each(function () {
        var code=$(this).data('ogtrans');
        if (typeof code != 'undefined') {
          // if (this.nodeName == 'A')
          // {
            // trick below works if span.ui-btn-text found otherwise straight stuff
          if ($(this).find('span.ui-btn-text').length != 0)
            $(this).find('span.ui-btn-text').html(that.getMessage(code.toUpperCase()));
          //}
          else // BUTTON/LABEL/P/H1/OPTION/SPAN
            $(this).html(that.getMessage(code.toUpperCase()));

          }
       });
    },

  isValidDate: function (s, fmt) {
    if (s.length != 10)
      return false;
    if (typeof fmt == 'undefined') fmt='fr';

    var bits=new Array();

        switch(fmt) {
      case 'fr':
      case 'en': bits = s.split('/'); break
      case 'de': bits = s.split('.'); break
      case 'es': bits = s.split('-'); break

      default: alert('isValidDate : Date format split not implemented')
    }

    if (bits.length != 3)
       return false;
     if ( (isNaN(Number(bits[0]))) || (isNaN(Number(bits[1]))) || (isNaN(Number(bits[2]))) )
       return false;
    var d;
    switch(fmt) {
      case 'fr':
      case 'en':
      case 'es':
      case 'de': d = new Date(bits[2], bits[1] - 1, bits[0]);
        if ( (d.getMonth() + 1) != Number(bits[1]) || d.getDate() != Number(bits[0]) || (d.getFullyear() != Number(bits[2])))
          return false;
        break;
      default:
        alert('isValidDate : Date format check not implemented')
    }

    return d ;
},


  append: function (to, what)
    {
      if (to.length == 0)
        to = what;
      else
        to.append(what)

      return to;
    },
}
 // return
 }) ();
