"use strict";

/*
V 1.0.1 Add check and console messages in changePage

this library allows you to have multiple pages in a single HTML file, to create single-page-applications.
To do so you must have multiple elements inside your JS and HTML file.

First here's what you need in your JS file :

oge = new ogePage();

This will initiate the "ogePage" object. You also need to add a reference in your HTML document in order to link them.

<script src="pathtoyourproject/js/01-og-spa.js"></script>
<link rel="stylesheet" type="text/css" href="pathtoyourproject/css/ogePage.css"/>

You need Jquery in order for this Library to work

I / Automatically

You can make it so it automatically handles the page Changing on hash change.

app.js :

$(window).on('hashchange', function(){
  oge.changePage(window.location.hash)
})

index.html :

<div class="testHeader">
	<a href="#pageTest">go to test Page</a>
	<a href="#pageWelcome">go to Welcome Page</a>
</div>
<div class="ogeContainer">
	<div class="ogePage ogeShow" id="pageWelcome">
		<h1>Welcome to this website !</h1>
	</div>
	<div class="ogePage" id ="pageTest">
		<h1>It Works ! you changed it !</h1>
	</div>
</div>

With this, you should have a mini single page app. And you can also see that if you change Page,
you can return to the previous one using your browser's "previous page" button.

II / Manually

You can manually call the changePage function. changePage is the function which switches your pages.
It does so using a hash you provide in the function, like this :

changePage("#yourPageDiv")

This method doesn't rely on page hash and doesn't change it.

III / Handling Events

You can , on your js file, handle the "BeforeOgChangePage" and "AfterOgChangePage".

They Allow your to perform actions before and after changing Page, you can for example prevent changing Page if something is not ready

this will be something like :

$(document).on("BeforeOgChangePage",function(){
   Your code here;
});

Some things :
You can see that there is a Container around the pages (ogeContainer class).
This is to place the pages properly in relation to other elements in the HTML file.
If you don't want to have a header or other things that won't change, you can discard this class.

You also don't need to have a specific class for the links you use, because this library makes use of the fact that the hash changes when you click on links
What you do need to do, however, is put the "ogeShow" class on your default page.

*/

function ogePage(mainContainerId, startPage) {
    this.currentPage={};
    this.previousPage={};
    this.futurePageid='';
    this.defaultContainerId = mainContainerId || "#masterContainer";  this.currentPage[this.defaultContainerId] =
      typeof startPage !== 'undefined' ? ( startPage.charAt(0)=='#' ? startPage.slice(1) : startPage ) : "landingPage";
    this.currentPage[this.defaultContainerId] = startPage || "landingPage";
    return this;
}

/* Setters for the Pages, you can do them individually or both at the same time */

ogePage.prototype.setCurrent = function(currentPage, containerId) {
    containerId = containerId || this.defaultContainerId;
    this.currentPage[containerId] = currentPage.charAt(0)=='#' ? currentPage.slice(1) : currentPage ;
}

ogePage.prototype.setPrevious = function(previousPage, containerId) {
    containerId = containerId || this.defaultContainerId;
    this.previousPage[containerId] = previousPage;
}

ogePage.prototype.setPages = function(previousPage, currentPage, containerId) {
    containerId = containerId || this.defaultContainerId;
    currentPage = currentPage.charAt(0)=='#' ? currentPage.slice(1) : currentPage;
    this.currentPage[containerId] = currentPage;
    previousPage = previousPage.charAt(0)=='#' ? previousPage.slice(1) : previousPage;
    this.previousPage[containerId] = previousPage;
}

/* getters for the pages */

ogePage.prototype.getCurrent = function(containerId){
    containerId = containerId || this.defaultContainerId;
	return this.currentPage[containerId];
}


ogePage.prototype.getPrevious = function(containerId){
    containerId = containerId || this.defaultContainerId
	return this.previousPage[containerId];
}

/* Change Page function, to switch the page depending on page hash.

Also initializes the currentPage and previousPage variables on first change

You can also, on your js file, handle the "BeforeOgChangePage" and "AfterOgChangePage".

They Allow your to perform actions before and after changing Page, you can for example prevent changing Page if something is not ready

$(document).on("BeforeOgChangePage",function(){
   Your code here;
});
*/

ogePage.prototype.changePage = function(hash, containerHash) {
    var that=this;
    var futurePageid = hash.charAt(0)=='#' ? hash.slice(1) : hash,
     previousPage, currentPage;
    containerHash = containerHash || this.defaultContainerId;
    if ( ($(containerHash).length == 0 ) || (!  $(containerHash).hasClass ('ogecontainer')) ) {
        console.log("ogePage.changePage : containerHash: " + containerHash + " doesn't exist or is not a valid container")
        return false;
        }

    previousPage = $('.ogepage.ogeshow', containerHash).attr("id");

    if (typeof previousPage === 'undefined' ) {
        console.log("ogePage.changePage previousPage "+previousPage+" is undefined inside "+containerHash);
        return false;
    }

    if ( $(hash, containerHash) === undefined) {
      console.log("ogePage.changePage hash "+hash+" is undefined or doesn't belong to "+containerHash);
      return false;
    }
    var eventBefore = $.Event ("oge:beforeChangePage");

    $(document).trigger(eventBefore,
         { fromPage: previousPage, toPage: futurePageid, container:containerHash } )
    if ( (eventBefore.result == false) || (eventBefore.isDefaultPrevented() ))
        return false;
    $('#'+previousPage).removeClass("ogeshow")
    this.setPages(previousPage, hash, containerHash);
    $(hash, containerHash).addClass("ogeshow");

    $(document).trigger("oge:afterChangePage", { fromPage: currentPage, toPage: futurePageid, container:containerHash });
    return true;
};

/* allows the user to prevent or enable the changePage function

You can for example put a checkbox that prevents the user from changing page unless it's checked : Here's the example input :

<input type="checkbox" id="checkTest">

And the code in javascript (using Jquery)

$(window).on('hashchange', function(){
  if ($("#checkTest").prop('checked')){
  	oge.enableChange();
  }
 	else {
  	oge.preventChange();
 }
  oge.changePage(window.location.hash)
})

*/

ogePage.prototype.preventChange = function() {
    this.evtBef.returnValue = false;
}

ogePage.prototype.enableChange = function() {
    this.evtBef.returnValue = true;
}
